import React from 'react';
import MainLayout from '../../layouts/main';
import { Button, Typography } from '@material-ui/core';
import styled from '../../styled';
import { Check, Dash } from '../../components/Check';
import PageHead from '../../components/PageHead';
import { sendCtaClickEvent } from '../../tracking';
import { SEO } from '../../components/SEO';
import { SignupButton } from '../../components/SignupButton';
import { ComparisonTableContent } from '../../components/ComparisonTable';
import { GetStarted } from '../../components/GetStarted';
import { BrunaTestimonial } from '../../components/TestimonialBar';
import { Usps } from '../../components/Usps';
import { FeatureTable } from '../../components/FeatureTable';
import { CALENDAR_LINK } from '../../constants/demo-link';
import { DemoButton } from '../../components/DemoButton';
import { Link } from 'gatsby';

const Hr = styled('hr')`
  border: 1px solid #eee;
  margin-bottom: 48px;
  max-width: 300px;
`;

const Headline = styled(Typography)`
  max-width: 800px;
  margin: 0 auto ${(p) => p.theme.spacing(3)}px;
  width: 90%;

  @media (max-width: 1200px) {
    font-size: ${(p) => p.theme.typography.h5.fontSize}px;
    line-height: ${(p) => p.theme.typography.h5.lineHeight};
    margin: 0 auto ${(p) => p.theme.spacing(2)}px;
  }
`;

export default () => (
  <MainLayout>
    <div>
      <SEO
        pathname="/alternatives/voluum/"
        title="Looking for Voluum alternatives? Try Affilimate"
        description="Looking for the perfect affiliate analytics platform? Here's how Affilimate stacks up against Voluum as an alternative."
      />
      <PageHead style={{ marginBottom: 0 }}>
        <Headline variant="h4" component="h1">
          Looking for a Voluum alternative?
        </Headline>
        <Typography
          paragraph
          style={{ maxWidth: '600px', padding: '24px', margin: '0 auto' }}
        >
          Voluum is designed for affiliates to run campaigns with paid traffic.
          Affilimate is designed for affiliate publishers with primarily organic
          traffic, who want to improve their commerce content's profitability.
        </Typography>
        <SignupButton />
        <DemoButton style={{ marginLeft: '12px' }} />
      </PageHead>
      <br />
      <br />
      <Usps />
      <br />
      <br />
      <Typography
        variant="h5"
        component="h2"
        gutterBottom
        style={{ textAlign: 'center' }}
      >
        Affilimate vs. Voluum
      </Typography>
      <Typography
        paragraph
        variant="body2"
        color="textSecondary"
        style={{
          maxWidth: '600px',
          padding: '24px',
          textAlign: 'center',
          margin: '0 auto'
        }}
      >
        See if Affilimate or Voluum is the better fit for your business.
      </Typography>
      <div style={{ maxWidth: '800px', margin: '96px auto' }}>
        <FeatureTable>
          <thead>
            <tr>
              <th style={{ width: '60%' }}>Feature</th>
              <th style={{ width: '20%' }}>Affilimate</th>
              <th style={{ width: '20%' }}>Voluum</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Affiliate Revenue Dashboard</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Import historical commissions</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Revenue Attribution</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>No retention limits</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Works with existing links</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Content Analytics</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Conversion Heatmaps</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Revision History</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Link Checker (Amazon)</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
            <tr>
              <td>Paid traffic integrations</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Auto-optimize campaigns</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Realtime reporting</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Impression tracking</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Bot filtering</td>
              <td>
                <Check />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Custom metrics</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>API access</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Postbacks for custom conversions</td>
              <td>
                <Dash />
              </td>
              <td>
                <Check />
              </td>
            </tr>
            <tr>
              <td>Simple setup</td>
              <td>
                <Check />
              </td>
              <td>
                <Dash />
              </td>
            </tr>
          </tbody>
        </FeatureTable>
        <Typography
          paragraph
          color="textSecondary"
          style={{
            margin: '48px auto',
            maxWidth: '800px',
            textAlign: 'center'
          }}
        >
          <strong>Our conclusion.</strong> Voluum is a better solution for
          affiliates who run exclusively paid traffic campaigns. But it's not
          tailored for content websites. As a result there are very few{' '}
          <Link to="/integrations/" style={{ borderBottom: '1px solid' }}>
            affiliate network integrations
          </Link>
          , instead relying on error-prone postback reporting.
        </Typography>
        <Typography
          paragraph
          color="textSecondary"
          style={{
            margin: '48px auto',
            maxWidth: '800px',
            textAlign: 'center'
          }}
        >
          Affilimate is a better choice for publishers who drive traffic with
          SEO and want to optimize and analyze existing content and links. Plus,
          it's simpler to set up while offering detailed reporting. And unlike
          Voluum, lets you import historical commissions.
        </Typography>
      </div>
      <div style={{ margin: '0 auto 48px', textAlign: 'center' }}>
        <img
          src="/images/features/affiliate-dashboard/affiliate-dashboard.png"
          alt="affiliate dashboard"
          style={{ display: 'block', margin: '0 auto 48px', maxWidth: '800px' }}
        />
        <Typography variant="h5" component="h2" gutterBottom>
          Not sure which is right for your site?
        </Typography>
        <Typography
          paragraph
          style={{ margin: '24px auto', maxWidth: '800px' }}
        ></Typography>
        <Button
          variant="outlined"
          color="primary"
          href={CALENDAR_LINK}
          target="_blank"
          style={{ marginRight: '8px' }}
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Book a demo
        </Button>
        or
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: '8px' }}
          href="mailto:sales@affilimate.com"
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Get in touch
        </Button>
      </div>
      <BrunaTestimonial />
      <ComparisonTableContent />
      <GetStarted />
      <Hr />
      <div style={{ margin: '24px auto 48px', textAlign: 'center' }}>
        <Typography variant="h5" component="h2" gutterBottom>
          Still deciding?
        </Typography>
        <Typography
          paragraph
          style={{ margin: '24px auto', maxWidth: '800px' }}
        >
          We're happy to help determine the best solution for your unique
          situation as a publisher.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          href={CALENDAR_LINK}
          data-savvycal-embed
          target="_blank"
          style={{ height: '36px', marginRight: '8px' }}
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Book a demo
        </Button>
        or
        <Button
          variant="outlined"
          color="primary"
          style={{ marginLeft: '8px' }}
          href="mailto:sales@affilimate.com"
          onClick={() => {
            sendCtaClickEvent({ label: 'content' });
          }}
        >
          Get in touch
        </Button>
      </div>
    </div>
  </MainLayout>
);
